<template>
  <div class="pane_body">
    <v-card flat color="rgb(255 255 245)">
      <v-toolbar flat dense class="breads">
        <v-breadcrumbs :items="breadcrumb">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :to="item.to" :disabled="item.disabled">
              {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>

        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              text
              v-on="on"
              color="success"
              @click.stop="infotypeNewDialog = true"
            >
              new
            </v-btn>
          </template>
          <span>Create new Document Type</span>
        </v-tooltip>
        <SystemicAdd
          :infotypeNewDialog="infotypeNewDialog"
          :myId="parseInt(_decode(id))"
          @closeNew="closeNew"
          @submitNew="submitNew"
        />

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon text v-on="on" color="info" @click="refresh">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              text
              v-on="on"
              color="info"
              :to="{ name: 'admin.medics.systemic' }"
            >
              <v-icon>mdi-keyboard-return</v-icon>
            </v-btn>
          </template>
          <span>Back to Systemic History</span>
        </v-tooltip>
      </v-toolbar>
    </v-card>

    <v-container>
      <v-card color="transparent" flat>
        <v-card flat>
          <v-card-text>
            <v-data-table
              loader-height="2"
              :loading="loading"
              :headers="headers"
              :items="datalist"
              :items-per-page="8"
              :sort-by="['id']"
              :sort-desc="false"
              :footer-props="{ itemsPerPageOptions: [8, 16, 24] }"
            >
              <template v-slot:item.name="{ item }">
                {{ item.name | capitalize }}
              </template>
              <template v-slot:item.created="{ item }">
                {{ item.created | myDating }}
              </template>

              <template v-slot:item.active="{ item }">
                <v-icon :color="item.active ? 'blue' : 'red'">
                  {{ item.active ? "mdi-check" : "mdi-close" }}
                </v-icon>
              </template>

              <!-- <template v-slot:item.active="{ item }">
                <v-icon :color="item.active ? 'blue' : 'red'">
                  {{ item.active ? "mdi-check" : "mdi-close" }}
                </v-icon>
              </template> -->
              <template v-slot:item.actions="{ item }">
                <v-btn
                  @click.stop="$set(infoTypeViewDialog, item.id, true)"
                  text
                  x-small
                  color="primary"
                >
                  view
                </v-btn>
                <SystemicView
                  v-if="infoTypeViewDialog[item.id]"
                  :myId="item.id"
                  :name="item.name"
                  :infoTypeViewDialog="infoTypeViewDialog[item.id]"
                  @closeView="closeView"
                />

                <v-btn
                  @click.stop="$set(infoTypeEditDialog, item.id, true)"
                  text
                  x-small
                  color="success"
                >
                  edit
                </v-btn>
                <SystemicEdit
                  v-if="infoTypeEditDialog[item.id]"
                  :myId="item.id"
                  :name="item.name"
                  :infoTypeEditDialog="infoTypeEditDialog[item.id]"
                  @closeEdit="closeEdit"
                  @submitEdit="submitEdit"
                />

                <v-btn
                  text
                  x-small
                  color="pink"
                  @click.stop="$set(infoTypeRemoveDialog, item.id, true)"
                  >DELETE
                </v-btn>
                <SystemicRemove
                  v-if="infoTypeRemoveDialog[item.id]"
                  :myId="item.id"
                  :name="item.name"
                  :infoTypeRemoveDialog="infoTypeRemoveDialog[item.id]"
                  @closeRemove="closeRemove"
                  @submitRemove="submitRemove"
                />

                <!-- <v-btn
                  text
                  x-small
                  color="pink"
                  :to="{
                    name: 'admin.specialty.details',
                    params: { id: _encode(item.id), name: _encode(item.name) },
                  }"
                >
                  details
                </v-btn> -->

                <SystemicRemove
                  v-if="infoTypeRemoveDialog[item.id]"
                  :myId="item.id"
                  :name="item.name"
                  :infoTypeRemoveDialog="infoTypeRemoveDialog[item.id]"
                  @closeRemove="closeRemove"
                  @submitRemove="submitRemove"
                />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card>
    </v-container>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Utils from "@/mixins/utils";
import Restful from "@/services/RestFul";
import SystemicAdd from "../../components/medics/systemics_details/newone.vue";
import SystemicView from "../../components/medics/systemics_details/view.vue";
import SystemicEdit from "../../components/medics/systemics_details/edit.vue";
import SystemicRemove from "../../components/medics/systemics_details/remove.vue";
export default {
  props: {
    id: { type: String },
    name: { type: String },
  },
  components: { SystemicAdd, SystemicView, SystemicEdit, SystemicRemove },
  mixins: [Utils],
  data() {
    return {
      infotypeNewDialog: false,
      infoTypeViewDialog: {},
      infoTypeEditDialog: {},
      infoTypeRemoveDialog: {},
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      breadcrumb: [
        {
          text: "Dashboard",
          disabled: false,
          to: { name: "admin.dash" },
        },
        {
          text: this._decode(this.name) + " Details",
          disabled: true,
          href: "admin.medics.systemic.details",
        },
      ],
      loading: false,
      headers: [
        { text: "name", value: "name" },
        { text: "description", value: "description" },
        { text: "active", value: "active" },
        { text: "created", value: "created" },
        { text: "actions", value: "actions", align: "center" },
      ],
      datalist: [],
    };
  },
  created() {
    this.lists();
  },
  methods: {
    lists() {
      let id = parseInt(this._decode(this.id));
      console.log("id", id);
      this.loading = true;
      let self = this;
      Restful.general.systemiCatDetail
        .bySystemiCategory(id)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            this.datalist = response.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    refresh() {
      this.lists();
    },
    closeNew(p) {
      this.infotypeNewDialog = p.state;
    },
    submitNew(p) {
      console.log(p);
      let self = this;
      Restful.general.systemiCatDetail
        .create(p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data.message;
          this.lists();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data.message;
        });
      this.infotypeNewDialog = p.state;
    },
    closeView(p) {
      this.$set(this.infoTypeViewDialog, p.id, p.state);
    },
    closeEdit(p) {
      this.$set(this.infoTypeEditDialog, p.id, p.state);
    },
    submitEdit(p) {
      console.log(p);
      let self = this;
      Restful.general.systemiCatDetail
        .update(p.id, p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data.message;
          this.lists();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data.message;
        });
      this.$set(this.infoTypeEditDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.infoTypeRemoveDialog, p.id, p.state);
    },
    submitRemove(p) {
      console.log(p);
      let self = this;
      Restful.general.systemiCatDetail
        .remove(p.id)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data.message;
          this.lists();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data.message;
        });
      this.$set(this.infoTypeRemoveDialog, p.id, p.state);
    },
  },
};
</script>
